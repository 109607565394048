<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <customer-summary
          v-if="showCustomerSummary"
          :customerId="reservation.ProfileId"
          :locationId="reservation.LocationId"
        />

        <company-summary
          v-if="showCompanySummary"
          :companyId="reservation.CompanyId"
          :locationId="reservation.LocationId"
        />

        <voucher-summary
          v-if="showVoucherSummary"
          :voucherId="itemId"
        />
      </template>
    </ui-component-drawer>

    <modal-edit-detail
      v-if="showEditData"
      :showModal="showEditData"
      :onClickCancel="hideModal"
    />

    <ModalReviveReservation
      v-if="showReviveReservation"
      :showModal="showReviveReservation"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="!isLocked"
      :editFunction="setShowEditData"
      :editLabel="$t('Components.Reservation.BasicData.Link_Edit')"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.BasicData.Section_Title')
      }}</template>
    </ui-section-header>

    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Name') }}
          </td>
          <td>
            <span>{{ reservation.Name }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Profile') }}
          </td>
          <td>
            <span v-if="reservation.ProfileId > 0"><a
                @click="setShowCustomerSummary()"
                class="link"
              >{{
                reservation.ProfileName
              }}</a></span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Company') }}
          </td>
          <td>
            <span v-if="reservation.CompanyId > 0"><a
                @click="setShowCompanySummary()"
                class="link"
              >{{
                reservation.CompanyName
              }}</a></span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Status') }}
          </td>
          <td>
            <div>
              <span>{{ reservation.StatusId | getStatusName }}</span>
              <span
                v-if="reservation.StatusId === 3"
                class="has-margin-left"
              >
                <a
                  @click="setShowReviveReservation"
                  class="is-small"
                >
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'heartbeat']" />
                  </span>
                  Revive
                </a>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Language') }}
          </td>
          <td>
            <div>
              {{ $t('LanguageLabels.Language' + reservation.LanguageId) }}
            </div>
          </td>
        </tr>
        <tr v-if="!isLocked">
          <td>
            {{ $t('Components.Reservation.BasicData.Label_Voucher') }}
          </td>
          <td>
            <CheckVoucher
              :reservation="reservation"
              :isLocked="isLocked"
              @reservationVoucherApplied="reservationVoucherApplied"
              @showVoucherSummary="setShowVoucherSummary"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import reservationProvider from '@/providers/reservation'

const CheckVoucher = () => import('@/components/Vouchers/CheckVoucher')
const CompanySummary = () => import('@/components/Companies/CompanySummary')
const CustomerSummary = () => import('@/components/Customers/CustomerSummary')
const ModalEditBasicData = () =>
  import('@/components/Reservations/ModalEditReservationDetail')
const ModalReviveReservation = () =>
  import('@/components/Reservations/ModalReviveReservation')
const VoucherSummary = () => import('@/components/Vouchers/VoucherSummary')

export default {
  components: {
    CheckVoucher,
    'company-summary': CompanySummary,
    'customer-summary': CustomerSummary,
    'modal-edit-detail': ModalEditBasicData,
    ModalReviveReservation,
    'voucher-summary': VoucherSummary,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showEditData: false,
      showSideDrawer: false,
      showCompanySummary: false,
      showCustomerSummary: false,
      showVoucherSummary: false,
      showReviveReservation: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    createProposal() { },

    reservationVoucherApplied(reservation) {
      this.setReservation(reservation)
    },

    setShowEditData() {
      this.showEditData = true
    },

    hideModal() {
      this.showEditData = false
      this.showReviveReservation = false
    },

    setShowCompanySummary() {
      this.drawerTitle = 'Company details'
      this.showSideDrawer = true
      this.showCompanySummary = true
    },

    setShowCustomerSummary() {
      this.drawerTitle = 'Customer details'
      this.showSideDrawer = true
      this.showCustomerSummary = true
    },

    setShowReviveReservation() {
      this.showReviveReservation = true
    },

    setShowVoucherSummary(voucherId) {
      this.itemId = voucherId
      this.drawerTitle = 'Voucher details'
      this.showSideDrawer = true
      this.showVoucherSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.showSideDrawer = false
      this.showCompanySummary = false
      this.showCustomerSummary = false
      this.showReviveReservation = false
      this.showVoucherSummary = false
    },
  },
}
</script>
